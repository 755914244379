<template>
  <div id="app">
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view :key="$route.meta.key || $route.path" />
    </keep-alive>
    <router-view v-else :key="$route.meta.key || $route.path" />
  </div>
</template>

<script>
import jsPDF from "jspdf";
import { config } from "./utils/api"
export default {
  name: 'App',
  data() {
    return {
      shebeiShow: "",
    }
  },
  created() {
    localStorage.setItem('currIndex', 0)
    // 监听屏幕宽度
    // const mediaQuery = window.matchMedia("(max-width: 1300px)");
    // mediaQuery.addListener(this.updateScreen);
    // this.updateScreen(mediaQuery);

    this.handleconfig()
  },
  mounted() {

    // console.log("mounted")

  },
  methods: {
    // updateScreen(mediaQuery) {
    //   this.shebeiShow = mediaQuery.matches;
    //   console.log("shebeiShow", this.shebeiShow)
    //   if (this.shebeiShow) {
    //     this.$router.push({ path: '/pages/home/index' })
    //   } else {
    //     this.$router.push({ path: '/home' })
    //   }
    // },


    async handleconfig() {
      let res = await config()
      let configData = {
        bgColor: res.data[2].value,
        logoimg: res.data[0].value,
        bgimg: res.data[1].value,
        fontColor: res.data[3].value,
      }
      localStorage.setItem("config", JSON.stringify(configData))
    },


  },
}
</script>

<style>
body {
  margin: 0px;
}

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
</style>
