module.exports = {
    assemble: {
        欢迎来到网易标签订购系统: 'Welcome to the NetEase Label Procurement System',
        密码登录: "Log in with password",
        邮箱地址: 'Email Address',
        密码: "Password",
        请输入: "Please enter",
        登录: "Login",
        忘记密码: "Forgot Password",
        注册账号: "Register Account",
        登录成功: "Login successful",
        账号密码错误: "Account password error",
        已阅读并同意: "Have read and agree",
        服务协议: "Service Agreement",
        和: "And",
        隐私协议: "Privacy Policy",
        ICP备案: "ICP Filing: Yue B2-20090191-18",
        建议您使用: "Recommended to use Chrome,IE10 or above, and Edge browser to view this website",
        网易标签订购官方服务: "NetEase Label Procurement Official Service",
        账号注册: "Register Account",
        公司账号: "Company Account",
        部门账号: "Department Account",
        公司账号注册: "Company Account Registration",
        公司名称: "Company Name",
        验证码: "Verification Code",
        获取: "Get Code",
        上一步: "Previous Step",
        完善资料: "Complete Information",
        姓名: "Name",
        电话: "Telephone",
        手机号: "Mobile Number",
        提交: "Submit",
        部门账号注册: "Department Account Registration",
        已注册的公司名称: "Registered Company Name",
        已注册的公司账户: "Registered Company Account",
        部门名称: "Department Name",
        列项目部: "(Example: XX Project Department)",
        部门账户的邮箱地址: "Department Account's Email Address",
        部门账户邮箱收到的验证码: "Verification Code Received by Department Account's Email",
        部门账户的密码: "Department Account's Password",
        下一步: "Next Step",
        找回密码: "Retrieve Password",
        新密码: "New Password",
        确认密码: "Confirm Password",
        网易标签订购系统: "NetEase Label Procurement System",
        采购: "Purchase",
        订单管理: "Order Management",
        账号中心: "Account Center",
        消息通知: "Message Notification",
        退出登录: "Log Out",
        查看详情: "View Details",
        退出: "Log Out",
        您确定要退出吗: "Are You Sure You Want to Log Out?",
        取消: "Cancel",
        确定: "Confirm",
        已退出登录: "Logged Out",
        关于我们: "About Us",
        意见反馈: "Feedback",
        隐私政策: "Privacy Policy",
        用户协议: "User Agreement",
        联系我们: "Contact Us",
        用户手册: "Contact Us",
        产品信息: "Product Information",
        产品代号: "Product Code",
        产品描述: "Product Description",
        尺寸: "Size",
        单位: "Unit",
        授权区域: "Licensed area",
        授权产品子类别: "授权产品子类别",
        授权品牌: "Licensed Brand",
        授权产品子类别: "Licensed Subcategory",
        授权渠道: "Licensed Channel",
        授权产品类别: "Licensed Category",
        产品描述: "Product Description",
        数量: "Quantity",
        注: "Note",
        起订: "Minimum Order",
        每次数量加减以: "Increment/Decrement Quantity by",
        为单位: "As a Unit",
        立即下单: "Place Order Now",
        商品详情: "Product Details",
        请选择授权渠道: "Please select the Licensed channel",
        请选择授权产品类别: "Please select the Licensed product category",
        请选择授权品牌: "Please select the Licensed brand",
        请输入授权产品子类别: "Please enter the Licensed subcategory of the product",
        请选择授权区域: "Please select the Licensed area",
        请选择: "Please select",
        订单: "Order",
        订单要以: "Orders must be in",
        为单位: "As a Unit",
        订单商品: "Order Product",
        单价: "Unit Price",
        商品小计: "Product Subtotal",
        制造商名称输入完成后请按回车键: "Please press 'Enter' after entering 'Manufacturer Name'",
        制造商名称: "Manufacturer Name",
        邮编: "Postal Code",
        备注: "Remarks",
        制造商地址: "Manufacturer Address",
        联系电话: "Telephone Number",
        标签送货地址: "Label Delivery Address",
        点击添加地址: "Click to Add Address",
        默认地址: "Default Address",
        开票信息: "Billing Information",
        请选择增值税专用发票增值税普通发票: "Please choose (VAT Special Invoice/VAT General Invoice)",
        购方税号: "Taxpayer Identity Number",
        电子发票接收人资料: "Electronic Invoice Recipient Information",
        购方名称: "Buyer's Name",
        电子发票接收人邮箱: "Recipient Email",
        电子发票接收人手机号: "Recipient Mobile Number",
        立即下单: "Place Order Now",
        注产品运费顺丰到付: "Note: Product shipping cost, payable to SF Express upon delivery",
        请输入制造商名称: "Please enter the manufacturer's name",
        请输入制造商地址: "Please enter the manufacturer's address",
        请输入联系人: "Please enter contact person",
        请输入联系人电话: "Please enter contact person's telephone",

        请输入购方姓名: "Please enter the buyer's name",
        请输入购方税号: "Please enter the buyer's tax number",
        请输入购方地址电话: "Please enter the Taxpayer Identity Number",
        请输入开户行: "Please enter the bank Name",
        请输入银行账号: "Please enter the bank account number",
        请输入电子发票收件人邮箱: "Please enter the electronic invoice recipient's email",
        正在下单: "Placing Order",
        提交成功: "Submission Successful",
        选择部门: "Select Department",
        请输入商品标题或订单号: "Enter product title or order number",
        订单搜索: "Search",
        刷新: "Refresh",
        订单列表: "Order List",
        订单编号: "Order Number",
        合计: "Total",
        未通过审批: "Denied",
        待审批: "Pending Approval",
        审核通过: "Approved",
        生产中: "In Production",
        发货: "Shipped",
        完成: "Completed",
        订单详情: "Details",
        未通过原因: "Reason",
        确定导出该数据吗: "Are you sure you want to export this data?",
        下单日期: "Order Date",
        总价: "Total Price",
        提交成功: "Submission Successful",
        审核通过: "Approved",
        生产中: "In Production",
        发货: "Shipped",
        订单完成: "Order Completed",
        查看付款通知: "View Payment Notice",
        订单信息: "Order Information",
        联络人: "Contact Person",
        商品图片: "Product Image",
        代码范围: "Code Range",
        失效代码: "Invalid Code",
        代码文件: "Code File",
        标签物流资料: "Label Logistics Information",
        商品收货人公司名称: "Recipient Company Name",
        商品收件人地址: "Recipient Address",
        商品收货人姓名: "Recipient Name",
        邮政编号: "Postal Code",
        商品收货人联系电话: "Recipient Contact Telephone",
        商品物流单号: "Product Logistics Tracking Number",
        开票资料: "Invoice Information",
        发票类型: "Invoice Type",
        购方名称: "Purchaser's Name",
        购方税号: "Taxpayer Identity Number",
        电子发票接收人资料: "Electronic Invoice Recipient Information",
        电子发票收件人邮箱: "Recipient Email",
        电子发票收件人手机号: "Recipient Mobile Number",


        我的账户: "My Account",
        地址: "Address",
        返回: "Return",
        添加地址: "Add Address",
        编辑: "Edit",
        删除: "Delete",
        公司名称: "Company Name",
        是否设置为默认地址: "Set as Default Address?",
        编辑地址: "Edit Address",
        新增地址: "Add New Address",
        删除成功: "Delete Successful",
        添加成功: "Add Successful",
        请检查是否漏填地址信息或邮编: "Please check if any address information or postal code is missing",
        编辑成功: "Edit Successful",
        原密码: "Original Password",
        新密码: "New Password",
        请确认密码和原密码一致: "Please ensure the new password matches the original password",
        密码修改成功请重新登录: "Password successfully changed, please log in again",
        个人档案: "Personal Profile",
        更改手机号: "Change Mobile Number",
        添加邮箱: "Add Email",
        用户名称: "User Name",
        更换: "Replace",
        添加: "Add",
        新用户名: "New Username",
        新手机号: "New Mobile Number",
        发送验证码: "Send Verification Code",
        新邮箱: "New Email",
        导出订单列表: "Export Order List",
        意见反馈: "Feedback",
        反馈内容意见: "Feedback Content/Comments",
        图片选填提供问题截图: "Picture (Optional, provide a screenshot of the issue)",
        联系方式: "Contact Information",
        反馈成功: "Feedback Successfully Submitted",



        下载付款通知书: "Download Payment Notice",
        上海茂侨实业有限公司: "Shanghai Maoqiao Industrial Co., Ltd.",
        付款通知单: "Payment Notice",
        至: "To",
        共计: "Total",
        收件人的公司名称: "Recipient's Company Name",
        收件人的姓名: "Recipient's Name",
        收件人的联系电话: "Recipient's Contact Telephone",
        收货地址: "Shipping Address",
        邮政编码: "Postal Code",
        寄送地区: "Delivery Area",
        中国: "China",
        支付方法银行转帐: "*Payment Method: Bank Transfer",
        送货在确实收到货款起计的7个工作天内安排将订购的货品送出: "*Delivery: Arrange to dispatch the ordered products within 7 working days after the payment is received for sure.",
        工作日星期一至星期五的上午9时30分至下午5时30分法定假期除外: "*Working Days: Monday to Friday from 9:30 am to 5:30 pm (except public holidays);",
        付款请按本通知单的总金额存入以下的银行账户: "*Payment: Please deposit the total amount of this notice into the following bank account",
        收款银行名称中国建设银行上海市白鶴支行: " Bank Name for Receipt: 中国建设银行上海市白鹤支行",
        账户号码: " Account Number: 3105 0183 4100 0000 0667",
        收款人名称上海茂侨实业有限公司: "Recipient Name: 上海茂侨实业有限公司",
        联行号: "CNAPS: 1052 9008 3042",
        此通知单仅作付款通知不可用于财务入帐电子版增值稅发票会于15个工作日内寄达订单所留的邮箱: "*This notice is only for payment notification and cannot be used for financial accounting; the electronic VAT invoice will be sent to the email provided in the order within 15 working days.",
        用户手册: "User Manual",
        手册名称: "Manual Name",
        操作: "Operation",
        下载手册: "Download Manual",
        按当天中间汇率换算成美元: 'Converted into US dollars at the days midpoint exchange rate',


        请同意服务协议和隐私协议: "Please agree to the 'Services Agreements' and 'Privacy Agreements'",
        登录成功: "Login Successful",
        账号密码错误: "Account Password Incorrect",
        注初次注册账号请使用公司公共邮箱地址注册以避免员工离职造成无法找回密码: "Note: For initial account registration, please use the company's public email address to avoid the inability to retrieve passwords when employees leave.",
        请输入公司名称: "Please enter the company name",
        请输入邮箱地址: "Please enter the email address",
        请输入验证码: "Please enter the verification code",
        请输入密码: "Please enter the password",
        请输入被授权中文名称: "Please enter the authorized Chinese name",
        请输入姓名: "Please enter your name",
        请输入手机号: "Please enter your mobile number",
        请输入收件地址: "Please enter the delivery address",
        验证码发送成功: "Verification code sent successfully",
        请等待网易批准后登入系统: "Please wait for NetEase approval before logging into the system",
        请输入新密码: "Please enter a new password",
        请确认密码: "Please confirm the password",
        该公司名称已被注册: "The company name has been registered!",
        该公司名称或账户不存在: "The company name or account does not exist!",

        请输入已注册的公司名称: "Please enter a registered company name",
        请输入已注册的公司账户: "Please enter your registered company account",


        联系人: "Contact person",
        请输入备注: 'Please enter remarks',
        开票类型: "Billing type",
        请选择收货地址: "Please select a delivery address",


        修改密码: 'Change password',
        首页: 'Home',
        被授权商名称: 'Name of the licensee',
        商品名称: 'Product Name',
        总计: "Total",
        价格: "Price",
        确定删除删除这个地址吗: 'Are you sure to delete Delete this address?',
        提示: 'Tips',
        请登录后操作: 'Please log in and operate',
        授权品牌子类别: 'Licensed Brands subcategory',
        订单状态: 'status',
        产品类别: 'Product category',
        电子发票增值税专用发票: 'Electronic Invoice (VAT Special Invoice)',
        电子发票增值税普通发票: 'Electronic Invoice (VAT Normal Invoice)',


        复购此单: 'Reorder',
        商业发票: 'Commercial Invoice',
        此通知单仅作付款通知不可用于财务入帐商业发票会于15个工作日内寄达订单所留的邮箱: '*This notice is only for payment notification and cannot be used for financial accounting; the Commercial Invoice will be sent to the email provided in the order within 15 working days.',
        我的: "Mine",
        地址列表: "Address List",
        默认: "Default",
        请输入邮箱号: "Please enter your email number",
        请输入电话: "Please enter phone number",
        个人中心: "Mine",
        个人资料: "Personal data",
        保存: "Save",
        正在提交: "Be submitting",
        商品信息: " Product detail",
        请输入新手机号: "Please enter your new phone number",



        鉴定为真: "Verify as true",
        鉴定为假: "Verify as false",
        系统鉴定为真: "System verification is true",
        系统鉴定为假: "System verification is false",
        系统码: "System code",
        无效系统码: "Invalid system code",
        继续扫码: "Continue to scan the code",
        请输入防伪码: "Please input the security code",
        点击完成验证: "Click to verification",



    }
}