
import request from "./request";
import requestcode from "./requestcode";




//获取验证码
export function sendcode(data) {
    return request(
        {
            url: "/api/ems/send",
            method: "post",
            data,
        }

    );
}

//校验验证码
export function check(data) {
    return request(
        {
            url: "/api/ems/check",
            method: "post",
            data,
        }
    );
}

//邮箱注册
export function emailregister(data) {
    return request(
        {
            url: "/api/login/emailregister",
            method: "post",
            data,
        }
    );
}

//登录
export function emaillogin(data) {
    return request(
        {
            url: "/api/login/emaillogin",
            method: "post",
            data,
        }
    );
}

//找回密码
export function findresetpwd(data) {
    return request(
        {
            url: "/api/login/resetpwd",
            method: "post",
            data,
        }
    );
}

//商品列表
export function progetlist(data) {
    return request(
        {
            url: "/api/product/getlist",
            method: "post",
            data,
        }
    );
}

//商品详情
export function prodetail(data) {
    return request(
        {
            url: "/api/product/detail",
            method: "post",
            data,
        }
    );
}
//创建订单
export function createorder(data) {
    return request(
        {
            url: "/api/order/createorder",
            method: "post",
            data,
        }
    );
}

//获取部门列表
export function getcompanyuser(data) {
    return request(
        {
            url: "/api/user/getcompanyuser",
            method: "post",
            data,
        }
    );
}
//获取制造商列表
export function getmanufacturerlist(data) {
    return request(
        {
            url: "/api/order/getmanufacturerlist",
            method: "post",
            data,
        }
    );
}
//获取订单列表
export function getOrderlist(data) {
    return request(
        {
            url: "/api/order/getlist",
            method: "post",
            data,
        }
    );
}
//订单详情
export function getinfodata(data) {
    return request(
        {
            url: "/api/order/getinfo",
            method: "post",
            data,
        }
    );
}

//修改手机号
export function resetmobile(data) {
    return request(
        {
            url: "/api/user/resetmobile",
            method: "post",
            data,
        }
    );
}


//修改密码
export function resetpwd(data) {
    return request(
        {
            url: "/api/user/resetpwd",
            method: "post",
            data,
        }
    );
}


//修改昵称
export function updatenickname(data) {
    return request(
        {
            url: "/api/user/updatenickname",
            method: "post",
            data,
        }
    );
}
//修改邮箱
export function resetemail(data) {
    return request(
        {
            url: "/api/user/resetemail",
            method: "post",
            data,
        }
    );
}

//获取地址
export function getAdresslist(data) {
    return request(
        {
            url: "/api/site/getlist",
            method: "post",
            data,
        }
    );
}

//添加地址
export function addAdress(data) {
    return request(
        {
            url: "/api/site/save",
            method: "post",
            data,
        }
    );
}


//修改地址
export function editAdress(data) {
    return request(
        {
            url: "/api/site/edit",
            method: "post",
            data,
        }
    );
}
//删除地址
export function delAdress(data) {
    return request(
        {
            url: "/api/site/del",
            method: "post",
            data,
        }
    );
}

//改为默认地址
export function checkAdress(data) {
    return request(
        {
            url: "/api/site/check",
            method: "post",
            data,
        }
    );
}

//意见反馈
export function feedback(data) {
    return request(
        {
            url: "/api/index/feedback",
            method: "post",
            data,
        }
    );
}

//单页
export function onepage(data) {
    return request(
        {
            url: "/api/index/onepage",
            method: "post",
            data,
        }
    );
}

//获取用户手册
export function getuserhandbook(data) {
    return request(
        {
            url: "/api/user/getuserhandbook",
            method: "post",
            data,
        }
    );
}

//获取消息列表
export function getmessagelist(data) {
    return request(
        {
            url: "/api/index/getmessagelist",
            method: "post",
            data,
        }
    );
}

//未读消息数量

export function getmessagecount(data) {
    return request(
        {
            url: "/api/index/getmessagecount",
            method: "post",
            data,
        }
    );
}


//验证公司是否存在
export function checkCompany(data) {
    return request(
        {
            url: "/api/login/check_company",
            method: "post",
            data,
        }
    );

}



//授权区域
export function userArea(data) {
    return request(
        {
            url: "/api/index/user_area",
            method: "post",
            data,
        }
    );

}


//授权区域
export function config(data) {
    return request(
        {
            url: "/api/login/config",
            method: "post",
            data,
        }
    );

}


//验证接口
export function authCode(data) {
    return requestcode(
        {
            url: `code-query/authCode?label_code=${data.label_code}`,
            method: "get",
            data,
        }
    );

}


