import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import request from "./utils/request"
import router from "@/router/index"
import JsonExcel from 'vue-json-excel'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import i18n from "./assets/lang/lang"

import Vant from 'vant';
import 'vant/lib/index.css';
import { Toast } from 'vant';

Vue.use(Toast);

Vue.use(Vant);


Vue.config.productionTip = false
Vue.prototype.$request = request
Vue.use(ElementUI);
Vue.component('downloadExcel', JsonExcel)
new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
