import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
const mediaQuery = window.matchMedia("(max-width: 1300px)");
let shebeiShow = mediaQuery.matches;
console.log("shebeiShow", shebeiShow)
const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: shebeiShow == false ? "home" : "/pages/home/index",
      component: () => shebeiShow == false ? import("@/views/home/Index.vue") : import("@/pages/home/index.vue"),
      meta: {
        title: '首页',
        // keepAlive: true
      }
    },
    {
      path: '/home',
      name: "home",
      component: () => import("@/views/home/Index.vue"),
      meta: {
        title: '首页',
        // keepAlive: true
      }
    },
    {
      path: '/login',
      name: "login",
      component: () => import("@/views/login/index.vue"),
      meta: {
        title: '登录',
        // keepAlive: true
      }
    }, {
      path: '/login/findPassword',
      name: "findpassword",
      component: () => import("@/views/login/findPassword"),
      meta: {
        title: '找回密码',
        // keepAlive: true
      }
    }, {
      path: '/login/register',
      name: "register",
      component: () => import("@/views/login/register"),
      meta: {
        title: '账号注册',
        // keepAlive: true
      }
    }, {
      path: '/login/companyAccount',
      name: "companyAccount",
      component: () => import("@/views/login/companyAccount"),
      meta: {
        title: '公司账号注册',
        // keepAlive: true
      }
    }, {
      path: '/login/departmentAccount',
      name: "departmentAccount",
      component: () => import("@/views/login/departmentAccount"),
      meta: {
        title: '部门账号注册',
        // keepAlive: true
      }
    },
    {
      path: '/home/aboutUs',
      name: "aboutUs",
      component: () => import("@/views/home/aboutUs"),
      meta: {
        title: '关于我们',
        // keepAlive: true
      }
    },
    {
      path: '/login/yinsi',
      name: "yinsi",
      component: () => import("@/views/login/yinsi"),
      meta: {
        title: '隐私协议',
        // keepAlive: true
      }
    },
    {
      path: '/home/feedBack',
      name: "feedBack",
      component: () => import("@/views/home/feedBack"),
      meta: {
        title: '意见反馈',
        // keepAlive: true
      }
    }, {
      path: '/home/privacyPolicy',
      name: "privacyPolicy",
      component: () => import("@/views/home/privacyPolicy"),
      meta: {
        title: '隐私政策',
        // keepAlive: true
      }
    }, {
      path: '/home/userAgreement',
      name: "userAgreement",
      component: () => import("@/views/home/userAgreement"),
      meta: {
        title: '用户协议',
        // keepAlive: true
      }
    },
    {
      path: '/home/contactAs',
      name: "contactAs",
      component: () => import("@/views/home/contactAs"),
      meta: {
        title: '联系我们',
        // keepAlive: true
      }
    }, {
      path: '/home/userManual',
      name: "userManual",
      component: () => import("@/views/home/userManual"),
      meta: {
        title: '用户手册',
        // keepAlive: true
      }
    },
    {
      path: '/home/productDetail',
      name: "productDetail",
      component: () => import("@/views/home/productDetail"),
      meta: {
        title: '商品详情',
        // keepAlive: true
      }
    },
    {
      path: '/home/payBook',
      name: "payBook",
      component: () => import("@/views/home/payBook"),
      meta: {
        title: '付款通知书',
        // keepAlive: true
      }
    },
    {
      path: '/home/excelList',
      name: "excelList",
      component: () => import("@/views/home/excelList"),
      meta: {
        title: '下载订单列表',
        // keepAlive: true
      }
    },
    {
      path: '/home/balance',
      name: "balance",
      component: () => import("@/views/home/Balance"),
      meta: {
        title: '结算',
        keepAlive: true
      }
    },
    {
      path: "/mine/orderdetail",
      name: "Orderdetail",
      component: () => import("@/views/mine/components/Orderdetail"),
      meta: {
        title: "订单详情"
      }
    },
    {
      path: '/mine',
      name: 'Mine',
      component: () => import("@/views/mine/index"),
      meta: {
        title: '帐号中心',
        // keepAlive: true
      },
      children: [
        { path: '/mine', redirect: '/mine/account' },
        {
          path: '/mine/account',
          name: "MineAccount",
          component: () =>
            import("@/views/mine/components/MyAccount"),
          meta: {
            title: "我的账户",
            //  keepAlive: true

          }
        },
        {
          path: "/mine/address",
          name: "MineAddress",
          component: () => import("@/views/mine/components/Address"),
          meta: {
            title: "地址"
          }
        },
        {
          path: "/mine/changepassword",
          name: "Changepassword",
          component: () => import("@/views/mine/components/Changepassword"),
          meta: {
            title: "修改密码"
          }
        },
        {
          path: "/mine/orderlist",
          name: "OrderList",
          component: () => import("@/views/mine/components/OrderList"),
          meta: {
            title: "订单列表"
          }
        },
      ]
    }, {
      path: "/pages/login/index",
      name: "login",
      component: () => import("@/pages/login/index"),
      meta: {
        title: "登录"
      }
    }, {
      path: "/pages/login/yinsi",
      name: "yinsi",
      component: () => import("@/pages/login/yinsi"),
      meta: {
        title: "隐私协议"
      }
    },
    {
      path: "/pages/home/index",
      name: "home",
      component: () => import("@/pages/home/index"),
      meta: {
        title: "首页"
      }
    },
    {
      path: "/pages/order/index",
      name: "order",
      component: () => import("@/pages/order/index"),
      meta: {
        title: "订单管理"
      }
    },
    {
      path: "/pages/mine/index",
      name: "mine",
      component: () => import("@/pages/mine/index"),
      meta: {
        title: "我的"
      }
    },
    {
      path: "/pages/login/forgotPassword",
      name: "forgotPassword",
      component: () => import("@/pages/login/forgotPassword"),
      meta: {
        title: "忘记密码"
      }
    }, {
      path: "/pages/login/accountRegistration",
      name: "accountRegistration",
      component: () => import("@/pages/login/accountRegistration"),
      meta: {
        title: "账号注册"
      }
    },
    {
      path: "/pages/login/company",
      name: "company",
      component: () => import("@/pages/login/company"),
      meta: {
        title: "公司账号"
      }
    },
    {
      path: "/pages/login/department",
      name: "department",
      component: () => import("@/pages/login/department"),
      meta: {
        title: "部门账号"
      }
    },
    {
      path: "/pages/mine/userInfo",
      name: "userInfo",
      component: () => import("@/pages/mine/userInfo"),
      meta: {
        title: "个人资料"
      }
    },
    {
      path: "/pages/mine/updateEmail",
      name: "updateEmail",
      component: () => import("@/pages/mine/updateEmail"),
      meta: {
        title: "更改邮箱"
      }
    },
    {
      path: "/pages/mine/updatePhone",
      name: "updatePhone",
      component: () => import("@/pages/mine/updatePhone"),
      meta: {
        title: "更改手机号"
      }
    },
    {
      path: "/pages/mine/addressList",
      name: "addressList",
      component: () => import("@/pages/mine/addressList"),
      meta: {
        title: "地址"
      }
    },
    {
      path: "/pages/mine/addoreditAddress",
      name: "addoreditAddress",
      component: () => import("@/pages/mine/addoreditAddress"),
      meta: {
        title: "地址"
      }
    },
    {
      path: "/pages/mine/uptatePassword",
      name: "uptatePassword",
      component: () => import("@/pages/mine/uptatePassword"),
      meta: {
        title: "修改密码"
      }
    },
    {
      path: "/pages/mine/feedBack",
      name: "feedBack",
      component: () => import("@/pages/mine/feedBack"),
      meta: {
        title: "意见反馈"
      }
    },
    {
      path: "/pages/mine/concatUs",
      name: "concatUs",
      component: () => import("@/pages/mine/concatUs"),
      meta: {
        title: "联系我们"
      }
    },
    {
      path: "/pages/mine/userManual",
      name: "userManual",
      component: () => import("@/pages/mine/userManual"),
      meta: {
        title: "用户手册"
      }
    },
    {
      path: "/pages/home/message",
      name: "message",
      component: () => import("@/pages/home/message"),
      meta: {
        title: "消息通知"
      }
    },
    {
      path: "/pages/home/detail",
      name: "detail",
      component: () => import("@/pages/home/detail"),
      meta: {
        title: "商品详情"
      }
    },
    {
      path: "/pages/home/paceanOrder",
      name: "paceanOrder",
      component: () => import("@/pages/home/placeanOrder"),
      meta: {
        title: "结算"
      }
    },
    {
      path: "/pages/order/orderDetail",
      name: "payBox",
      component: () => import("@/pages/order/orderDetail"),
      meta: {
        title: "订单详情"
      }
    },
    {
      path: "/pages/order/payBox",
      name: "payBox",
      component: () => import("@/pages/order/payBox"),
      meta: {
        title: "付款通知书"
      }
    },
    {
      path: "/pages/order/orderList",
      name: "orderList",
      component: () => import("@/pages/order/orderList"),
      meta: {
        title: "订单列表"
      }
    },
    {
      path: "/valid",
      name: "valid",
      component: () => import("@/pages/valid/index"),
      meta: {
        title: "鉴定"
      }
    }



  ]
})



export default router