module.exports = {
    assemble: {
        欢迎来到网易标签订购系统: '欢迎来到网易标签订购系统',
        密码登录: "密码登录",
        邮箱地址: '邮箱地址',
        密码: "密码",
        请输入: "请输入",
        登录: "登录",
        忘记密码: "忘记密码",
        注册账号: "注册账号",
        登录成功: "登录成功",
        账号密码错误: "账号密码错误",
        已阅读并同意: "已阅读并同意",
        服务协议: "服务协议",
        和: "和",
        隐私协议: "隐私协议",
        ICP备案: "ICP备案：粤B2-20090191-18",
        建议您使用: "建议您使用Chrome、IE10及以上版本、Edge浏览器浏览本网站",
        网易标签订购官方服务: "网易标签订购官方服务",
        账号注册: "账号注册",
        公司账号: "公司账号",
        部门账号: "部门账号",
        公司账号注册: "公司账号注册",
        公司名称: "公司名称",
        验证码: "验证码",
        获取: "获取",
        上一步: "上一步",
        完善资料: "完善资料",
        姓名: "姓名",
        电话: "电话",
        手机号: "手机号",
        提交: "提交",
        部门账号注册: "部门账号注册",
        已注册的公司名称: "已注册的公司名称",
        已注册的公司账户: "已注册的公司账户",
        部门名称: "部门名称",
        列项目部: "(列：项目部)",
        部门账户的邮箱地址: "部门账户的邮箱地址",
        部门账户邮箱收到的验证码: "部门账户邮箱收到的验证码",
        部门账户的密码: "部门账户的密码",
        下一步: "下一步",
        找回密码: "找回密码",
        新密码: "新密码",
        确认密码: "确认密码",
        网易标签订购系统: "网易标签订购系统",
        采购: "采购",
        订单管理: "订单管理",
        账号中心: "账号中心",
        消息通知: "消息通知",
        退出登录: "退出登录",
        查看详情: "查看详情",
        退出: "退出",
        您确定要退出吗: "您确定要退出吗?",
        取消: "取消",
        确定: "确定",
        已退出登录: "已退出登录",
        关于我们: "关于我们",
        意见反馈: "意见反馈",
        隐私政策: "隐私政策",
        用户协议: "用户协议",
        联系我们: "联系我们",
        用户手册: "用户手册",
        产品信息: "产品信息",
        产品代号: "产品代号",
        产品描述: "产品描述",
        尺寸: "尺寸",
        单位: "单位",
        授权区域: "授权区域",
        授权品牌: "授权品牌",
        授权产品子类别: "授权产品子类别",
        授权渠道: "授权渠道",
        授权产品类别: "授权产品类别",
        产品描述: "产品描述",
        数量: "数量",
        注: "注",
        起订: "起订",
        每次数量加减以: "每次数量加减以",
        为单位: "为单位",
        立即下单: "立即下单",
        商品详情: "商品详情",
        请选择授权渠道: "请选择授权渠道",
        请选择授权产品类别: "请选择授权产品类别",
        请选择授权品牌: "请选择授权品牌",
        请输入授权产品子类别: "请输入授权产品子类别",
        请选择授权区域: "请选择授权区域",
        请选择: "请选择",
        订单: "订单",
        订单要以: "订单要以",
        为单位: "为单位",
        订单商品: "订单商品",
        单价: "单价",
        商品小计: "商品小计",
        制造商名称输入完成后请按回车键: "'制造商名称'输入完成后请按回车键",
        制造商名称: "制造商名称",
        邮编: "邮编",
        备注: "备注",
        制造商地址: "制造商地址",
        联系电话: "联系电话",
        标签送货地址: "标签送货地址",
        点击添加地址: "点击添加地址",
        默认地址: "默认地址",
        开票信息: "开票信息",
        请选择增值税专用发票增值税普通发票: "请选择（增值税专用发票/增值税普通发票）",
        购方税号: "购方税号",
        电子发票接收人资料: "电子发票接收人资料",
        购方名称: "购方名称",
        电子发票接收人邮箱: "电子发票接收人邮箱",
        电子发票接收人手机号: "电子发票接收人手机号",
        立即下单: "立即下单",
        注产品运费顺丰到付: "注：产品运费，顺丰到付",
        请输入制造商名称: "请输入制造商名称",
        请输入制造商地址: "请输入制造商地址",
        请输入联系人: "请输入联系人",
        请输入联系人电话: "请输入联系人电话",
        请输入或选择开票类型: "请输入或选择开票类型",
        请输入购方姓名: "请输入购方姓名",
        请输入购方税号: "请输入购方税号",
        请输入购方地址电话: "请输入购方地址电话",
        请输入开户行: "请输入开户行",
        请输入银行账号: "请输入银行账号",
        请输入电子发票收件人邮箱: "请输入电子发票收件人邮箱",
        正在下单: "正在下单",
        提交成功: "提交成功",
        选择部门: "选择部门",
        请输入商品标题或订单号: "请输入商品标题或订单号",
        订单搜索: "订单搜索",
        刷新: "刷新",
        订单列表: "订单列表",
        订单编号: "订单编号",
        合计合计: "合计",
        未通过审批: "未通过审批",
        待审批: "待审批",
        审核通过: "审核通过",
        生产中: "生产中",
        发货: "发货",
        完成: "完成",
        订单详情: "订单详情",
        未通过原因: "未通过原因",
        确定导出该数据吗: "确定导出该数据吗",
        下单日期: "下单日期",
        总价: "总价",
        提交成功: "提交成功",
        审核通过: "审核通过",
        生产中: "生产中",
        发货: "发货",
        订单完成: "订单完成",
        查看付款通知: "查看付款通知",
        订单信息: "订单信息",
        联络人: "联络人",
        商品图片: "商品图片",
        代码范围: "代码范围",
        失效代码: "失效代码",
        代码文件: "代码文件",
        标签物流资料: "标签物流资料",
        商品收货人公司名称: "商品收货人公司名称",
        商品收件人地址: "商品收件人地址",
        商品收货人姓名: "商品收货人姓名",
        邮政编号: "邮政编号",
        商品收货人联系电话: "商品收货人联系电话",
        商品物流单号: "商品物流单号",
        开票资料: "开票资料",
        发票类型: "发票类型",
        购方名称: "购方名称",
        购方税号: "购方税号",
        电子发票接收人资料: "电子发票接收人资料",
        电子发票收件人邮箱: "电子发票收件人邮箱",
        电子发票收件人手机号: "电子发票收件人手机号",

        我的账户: "我的账户",
        地址: "地址",
        返回: "返回",
        添加地址: "添加地址",
        编辑: "编辑",
        删除: "删除",
        公司名称: "公司名称",
        是否设置为默认地址: "是否设置为默认地址",
        编辑地址: "编辑地址",
        新增地址: "新增地址",
        删除成功: "删除成功",
        添加成功: "添加成功",
        请检查是否漏填地址信息或邮编: "请检查是否漏填地址信息或邮编",
        编辑成功: "编辑成功",
        原密码: "原密码",
        新密码: "新密码",
        请确认密码和原密码一致: "请确认密码和原密码一致",
        密码修改成功请重新登录: "密码修改成功,请重新登录",
        个人档案: "个人档案",
        更改手机号: "更改手机号",
        添加邮箱: "添加邮箱",
        用户名称: "用户名称",
        更换: "更换",
        添加: "添加",
        新用户名: "新用户名",
        新手机号: "新手机号",
        发送验证码: "发送验证码",
        新邮箱: "新邮箱",
        导出订单列表: "导出订单列表",
        意见反馈: "意见反馈",
        反馈内容意见: "反馈内容/意见",
        图片选填提供问题截图: "图片（选填，提供问题截图）",
        联系方式: "联系方式",
        反馈成功: "反馈成功",


        下载付款通知书: "下载付款通知书",
        上海茂侨实业有限公司: "上海茂侨实业有限公司",
        付款通知单: "付款通知单",
        至: "至",
        共计: "共计",
        收件人的公司名称: "收件人的公司名称",
        收件人的姓名: "收件人的姓名",
        收件人的联系电话: "收件人的联系电话",
        收货地址: "收货地址",
        邮政编码: "邮政编码",
        寄送地区: "寄送地区",
        中国: "中国",
        支付方法银行转帐: "*支付方法:  银行转帐",
        送货在确实收到货款起计的7个工作天内安排将订购的货品送出: "*送货：在确实收到货款起计的7个工作天内安排将订购的货品送出。",
        工作日星期一至星期五的上午9时30分至下午5时30分法定假期除外: "*工作日：星期—至星期五的上午9时30分 至下午5时30分（法定假期除外）；",
        付款请按本通知单的总金额存入以下的银行账户: "*付款 ：请按本通知单的总金额存入以下的银行账户",
        收款银行名称中国建设银行上海市白鶴支行: "收款银行名称：中国建设银行上海市白鹤支行",
        账户号码: " 账户号码：3105 0183 4100 0000 0667",
        收款人名称上海茂侨实业有限公司: "收款人名称：上海茂侨实业有限公司",
        联行号: "联行号：1052 9008 3042",
        此通知单仅作付款通知不可用于财务入帐电子版增值稅发票会于15个工作日内寄达订单所留的邮箱: "*此通知单仅作付款通知不可用于财务入帐，电子版增值稅发票会于15个工作日内寄达订单所留的邮箱。",
        用户手册: "用户手册",
        手册名称: "手册名称",
        操作: "操作",
        下载手册: "下载手册",
        按当天中间汇率换算成美元:'按当天中间汇率换算成美元',

        请同意服务协议和隐私协议: "请同意《服务协议》和《隐私协议》",
        登录成功: "登录成功",
        账号密码错误: "账号密码错误",
        注初次注册账号请使用公司公共邮箱地址注册以避免员工离职造成无法找回密码: "注:初次注册账号，请使用公司公共邮箱地址注册，以避免员工离职，造成无法找回密码",
        请输入公司名称: "请输入公司名称",
        请输入邮箱地址: "请输入邮箱地址",
        请输入验证码: "请输入验证码",
        请输入密码: "请输入密码",
        请输入被授权中文名称: "请输入被授权中文名称",
        请输入姓名: "请输入姓名",
        请输入手机号: "请输入手机号",
        请输入收件地址:"请输入收件地址",
        验证码发送成功: "验证码发送成功",
        请等待网易批准后登入系统: "请等待网易批准后登入系统",
        请输入新密码: "请输入新密码",
        请确认密码: "请确认密码",
        该公司名称已被注册: "该公司名称已被注册！",
        该公司名称或账户不存在: "该公司名称或账户不存在！",

        请输入已注册的公司名称: "请输入已注册的公司名称",
        请输入已注册的公司账户: "请输入已注册的公司账户",

        联系人: "联系人",
        请输入备注: '请输入备注',
        开票类型: "开票类型",
        请选择收货地址: "请选择收货地址",


        修改密码: '修改密码',
        首页: '首页',
        被授权商名称: '被授权商名称',
        商品名称: '商品名称',
        总计: "总计",
        价格: "价格",
        确定删除删除这个地址吗: '确定删除删除这个地址吗？',
        提示: '提示',
        请登录后操作: '请登录后操作',
        授权品牌子类别: '授权品牌子类别',
        订单状态: '订单状态',
        产品类别: '产品类别',
        电子发票增值税专用发票: '电子发票（增值税专用发票）',
        电子发票增值税普通发票: '电子发票（增值税普通发票）',

        复购此单: '复购此单',
        商业发票:'商业发票',
        此通知单仅作付款通知不可用于财务入帐商业发票会于15个工作日内寄达订单所留的邮箱:'*此通知单仅作付款通知不可用于财务入帐，商业发票会于15个工作日内寄达订单所留的邮箱.',
        我的: "我的",
        地址列表:"地址列表",
        默认:"默认",
        请输入邮箱号:"请输入邮箱号",
        请输入电话:"请输入电话",
        个人中心:"个人中心",
        个人资料:"个人资料",
        保存:"保存",
        正在提交:"正在提交",
        商品信息:"商品信息",
        请输入新手机号:"请输入新手机号",



        鉴定为真:"鉴定为真",
        鉴定为假:"鉴定为假",
        系统鉴定为真:"系统鉴定为真",
        系统鉴定为假:"系统鉴定为假",
        系统码:"系统码",
        无效系统码:"无效系统码",
        继续扫码:"继续扫码",
        请输入防伪码:"请输入防伪码",
        点击完成验证:"点击完成验证",

    }
}